.detail-container {
  background-color: rgba(223, 230, 230, 0.089);
  width: 300px;
}
.detail-container .property-container {
  padding: 10px;
  background-color: white;
}
.detail-container .bold-span {
  font-weight: 600;
}
.detail-container h5 {
  margin: 0;
}

.additional-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.additional-container .title {
  padding: 10px;
}
.additional-container .close-icon {
  cursor: pointer;
}

.body-source-container {
  display: flex;
  flex-direction: column;
  padding: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: start;
  white-space: pre-wrap;
}/*# sourceMappingURL=SourceContainerComponent.css.map */