.custom-multi-select .ant-select-selector {
  text-align: left;
  min-height: 100px;
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.custom-multi-select .ant-select-selection-placeholder {
  position: absolute;
  margin-top: 20px;
  right: 0;
  top: 0;
  text-align: left;
}

.custom-multi-select .ant-select-arrow {
  position: absolute;
  right: 8px; /* Adjust padding as needed */
  top: 20px; /* Adjust top position as needed */
}/*# sourceMappingURL=IndustryCheckTab.css.map */