.file-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  background: #fff;
  border-radius: 15px;
  padding: 9px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  position: relative;
  border-radius: 3px;
}

.file-input > [type=file] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}

.file-input > .label > .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} 
.file-input > .label > p {
  margin-left: 5px;
  margin-top: 15px;
  /* position: absolute; */
  top: 50%;
  text-align: left;
}

.uploadImageHeader {
  padding-bottom: 5px;
}/*# sourceMappingURL=UploadImageComponentStyles.css.map */