.chat-box {
  width: 100%;
  max-width: 800px;
  padding: 10px;
  max-height: 400px;
  min-height: 55px;
  // background-color: blue;
  height: auto;
  overflow: hidden;
  // background-color: white;
  // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 10px;
  .chat-box-input {
    max-width: 100%;
    height: 100%;
    position: absolute;
    // overflow-x: hidden;
    overflow: hidden;
    flex: 1;
    padding: 10px;
    min-height: 50px;
    display: flex;
    align-items: center;
    background-color: white;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border-radius: 10px;
    .input {
      position: relative;
      width: 100%;
      padding: 3px 45px 0px 10px;
      display: flex;
      height: 100%;
      flex-direction: column;
      //   justify-content: center;
      font-size: 1rem;
      font-weight: 400;
      border: none;
      resize: none;
      max-height: 150px;
      overflow-y: auto;
      &:focus {
        outline: none;
      }
      &:empty::before {
        content: "Send a message"; /* Your default content or placeholder text */
        color: #999;
        font-size: 1rem;

        position: absolute;
        margin: auto 0;
        font-weight: 600;
        left: 10px;
        pointer-events: none; /* Allows clicking through the placeholder text */
      }
    }
  }

  //making send btn absolute and in the vertical center and make content inside btn in the center
  .send-btn {
    position: absolute;
    right: 20px;
    // top: 50%;
    bottom: 10px;
    // transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 7px;
    border-radius: 5px;
    border: none;
    color: white;
    background-color: #077c82;
    // transition: background-color 0.2s ease-in-out;

    .loading-dot {
      font-size: 24px;
      font-weight: bold;
      color: grey;
    }

    /* Animation */
    @keyframes loadingDots {
      0% {
        content: ".";
      }
      33% {
        content: "..";
      }
      66% {
        content: "...";
      }
      100% {
        content: ".";
      }
    }

    .loading-dot::after {
      content: "";
      display: inline-block;
      text-align: start;
      width: 1em;
      animation: loadingDots 1.5s infinite;
    }
    &.loading {
      background-color: transparent;
    }
    &.disabled {
      // background-color: #d9d9e3;
      color: grey;
      background-color: transparent;
      cursor: default;
    }
    &:disabled {
      background-color: transparent;
      color: grey;
      cursor: default;
    }
  }

  .action-btn {
    display: flex;
    height: 50px;
    align-items: center;
    align-self: flex-end;
    padding: 10px 15px;
    border-radius: 10px;
    background-color: white;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    color: black;
    cursor: pointer;

    border: none;
    &.disabled {
      cursor: default;

      // padding-top: 2px;

      background-color: transparent;
      border: none;

      // pointer-events: none;
      color: rgb(219, 219, 219);
    }
    &:disabled {
      background-color: transparent;
      color: grey;
      cursor: default;
    }
    &.upload {
      border-radius: 10px;
      align-self: flex-start;
      height: 40px;
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    }
    &.upload-progress {
      border-radius: 10px;
      align-self: flex-start;
      height: 40px;
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    }
  }
}
