.chat-box {
  width: 100%;
  max-width: 800px;
  padding: 10px;
  max-height: 400px;
  min-height: 55px;
  height: auto;
  overflow: hidden;
  border-radius: 10px;
}
.chat-box .chat-box-input {
  max-width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  flex: 1;
  padding: 10px;
  min-height: 50px;
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 10px;
}
.chat-box .chat-box-input .input {
  position: relative;
  width: 100%;
  padding: 3px 45px 0px 10px;
  display: flex;
  height: 100%;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 400;
  border: none;
  resize: none;
  max-height: 150px;
  overflow-y: auto;
}
.chat-box .chat-box-input .input:focus {
  outline: none;
}
.chat-box .chat-box-input .input:empty::before {
  content: "Send a message"; /* Your default content or placeholder text */
  color: #999;
  font-size: 1rem;
  position: absolute;
  margin: auto 0;
  font-weight: 600;
  left: 10px;
  pointer-events: none; /* Allows clicking through the placeholder text */
}
.chat-box .send-btn {
  position: absolute;
  right: 20px;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 7px;
  border-radius: 5px;
  border: none;
  color: white;
  background-color: #077c82;
  /* Animation */
}
.chat-box .send-btn .loading-dot {
  font-size: 24px;
  font-weight: bold;
  color: grey;
}
@keyframes loadingDots {
  0% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
  100% {
    content: ".";
  }
}
.chat-box .send-btn .loading-dot::after {
  content: "";
  display: inline-block;
  text-align: start;
  width: 1em;
  animation: loadingDots 1.5s infinite;
}
.chat-box .send-btn.loading {
  background-color: transparent;
}
.chat-box .send-btn.disabled {
  color: grey;
  background-color: transparent;
  cursor: default;
}
.chat-box .send-btn:disabled {
  background-color: transparent;
  color: grey;
  cursor: default;
}
.chat-box .action-btn {
  display: flex;
  height: 50px;
  align-items: center;
  align-self: flex-end;
  padding: 10px 15px;
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  color: black;
  cursor: pointer;
  border: none;
}
.chat-box .action-btn.disabled {
  cursor: default;
  background-color: transparent;
  border: none;
  color: rgb(219, 219, 219);
}
.chat-box .action-btn:disabled {
  background-color: transparent;
  color: grey;
  cursor: default;
}
.chat-box .action-btn.upload {
  border-radius: 10px;
  align-self: flex-start;
  height: 40px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.chat-box .action-btn.upload-progress {
  border-radius: 10px;
  align-self: flex-start;
  height: 40px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}/*# sourceMappingURL=ChatInput.css.map */