.logo-spinner {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}/*# sourceMappingURL=ChatContainerComponent.css.map */