@import "../../../named_variable";

.table-stock {
  .table-row-light {
    background-color: #ffffff;
    // height: 40px;
  }
  .table-row-dark {
    background-color: #fbfbfb;
    // height: 40px;
  }
  .ant-table-thead .ant-table-cell {
    background-color: $primary-color;
    color: white;
  }

  .ant-table-cell.ant-table-column-sort.ant-table-column-has-sorters {
    background-color: $primary-color;
    color: white;
    &:hover {
      background-color: $primary-color;
    }
  }
  .ant-table-cell.ant-table-column-has-sorters {
    background-color: $primary-color;
    color: white;
    &:hover {
      background-color: $primary-color !important;
    }
  }

  // .ant-table-column-sorter {
  //   &::after {
  //     background-color: $primary-color;
  //   }
  // }

  .ant-table-wrapper {
    margin-bottom: 0;
  }
  .ant-table-pagination.ant-pagination {
    // margin: 0;
    margin-top: 15px;
    padding-bottom: 0;
  }
  .ant-select-selection-item {
    font-size: 16px;
  }
  .anticon.active {
    color: white;
  }
  .anticon {
    color: transparent;
  }
  // .ant-table-column-sorter.ant-table-column-sorter-full {
  //   color: white;
  //   &:hover {
  //     color: white;
  //   }
  // }
}
