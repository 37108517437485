@import "../../named_variable";
.ant-tabs-content-holder,
.ant-tabs-content,
.ant-tabs-tabpane {
  height: 100%;
}
.socialMediaDeleteIcon {
  float: right;
  margin-right: 5px;
  color: red;
  font-size: small;
  cursor: pointer;
}
.plus-icon {
  background-color: $secondary-color;
  border-radius: 50%;
}
.ant-tabs-nav {
  margin-bottom: 0 !important;
}
