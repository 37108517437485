.login-main-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-main-container .login-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.login-main-container .login-title {
  float: left;
}
.login-main-container .login-wrapper {
  max-width: 600px;
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-main-container .login-wrapper .button {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 25px;
  background-color: #ad9442;
  border: 0;
  max-width: -moz-fit-content;
  max-width: fit-content;
  justify-self: flex-end;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition-property: background-color;
  transition: 250ms linear;
}/*# sourceMappingURL=LoginPage.css.map */