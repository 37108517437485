.ad-container {
  width: 100%;
  height: 95%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.ad-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.ad-title {
  font-size: 28px;
}

.ad-text {
  font-size: 18px;
  padding: 0 5px;
  text-align: center;
}

.ad-scale {
  transform: scale(1.1);
}/*# sourceMappingURL=AccessDenied.css.map */