.detail-container {
  background-color: rgba(223, 230, 230, 0.089);
  width: 300px;
  // color: white;

  .property-container {
    padding: 10px;
    background-color: white;
  }
  .bold-span {
    font-weight: 600;
  }
  h5 {
    margin: 0;
  }
}

//Detail container for showing file properties
.additional-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .title {
    // background-color: rgb(68, 68, 68);

    padding: 10px;
  }
  .close-icon {
    cursor: pointer;
  }
}
.body-source-container {
  display: flex;
  flex-direction: column;
  padding: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: start;
  white-space: pre-wrap;
}
