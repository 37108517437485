.sidebar .navbar-brand img {
  height: 40px;
}
.sidebar .dropdown .user-container {
  color: black;
}
.sidebar .offcanvas-body {
  padding: 0;
}
.sidebar .sidebar-menu {
  padding: 10px;
  transition: background-color 200ms linear;
  cursor: pointer;
}
.sidebar .sidebar-menu:hover {
  background-color: rgba(183, 183, 183, 0.403);
}
.sidebar .navbar-brand {
  margin: 0;
}/*# sourceMappingURL=Sidebar.css.map */