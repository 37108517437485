@import "../../../../named_variable";
.custom-navbar {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  // min-width: 400px;
  align-items: center;
  img {
    height: 25px;
    width: 25px;
    padding: 10px;
    background-color: rgb(241, 239, 239);
    object-fit: contain;
    transition: transform 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    opacity: 0;
    cursor: pointer;
  }
  .active-img {
    border: 4px solid $primary-color;
    opacity: 1;
    height: 50px;
    width: 50px;
    transition: opacity 0.3s ease-in-out;
  }
  .prev-img,
  .next-img {
    opacity: 0.5;
  }
  button {
    background-color: transparent;
    border: none;
    font-size: 20px;
    color: $primary-color;

    &:disabled {
      color: grey;
    }
  }
}
