.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 30px;
}
