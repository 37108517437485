.table-container {
  flex: 1;
  overflow-y: auto;
  overflow-x: auto;
  margin-bottom: 5px;
  height: 100%;
}

.table .table-row-light {
  background-color: #ffffff;
}
.table .table-row-dark {
  background-color: #fbfbfb;
}
.table .ant-table-thead .ant-table-cell {
  background-color: #077c82;
  color: white;
}
.table .ant-table-thead .ant-checkbox {
  border: 2px solid white;
}
.table .ant-table-cell.ant-table-column-sort.ant-table-column-has-sorters {
  background-color: #077c82;
  color: white;
}
.table .ant-table-cell.ant-table-column-sort.ant-table-column-has-sorters:hover {
  background-color: #077c82;
}
.table .ant-table-cell.ant-table-column-has-sorters {
  background-color: #077c82;
  color: white;
}
.table .ant-table-cell.ant-table-column-has-sorters:hover {
  background-color: #077c82 !important;
}
.table .ant-table-sticky-scroll {
  display: none;
}
.table .ant-table-wrapper {
  margin-bottom: 0;
}
.table .ant-table-pagination.ant-pagination {
  margin: 0;
  padding-top: 20px;
  padding-bottom: 0;
}
.table .ant-select-selection-item {
  font-size: 16px;
}
.table .anticon.active {
  color: white;
}
.table .anticon {
  color: transparent;
}
.table .ant-table {
  width: -moz-fit-content;
  width: fit-content;
}/*# sourceMappingURL=TableComponent.css.map */