.image-viewer-container,
.image-viewer-container-transparent {
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
}

.image-viewer-container .image-viewer {
  background-color: transparent;
  height: 100%;
}

.image-viewer-container-transparent .image-viewer {
  background-color: transparent;
  height: 100%;
}

.image-viewer-container .react-viewer {
  min-height: auto;
}

.image-viewer-container-transparent .react-viewer {
  min-height: auto;
}

.image-viewer-container .react-viewer-mask {
  background-color: rgb(241, 239, 239);
}

.image-viewer-container-transparent .react-viewer-mask {
  background-color: rgb(241, 239, 239);
}

.image-bg-light {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
}

.image-bg-dark {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
}

.icon-toggle-dark {
  color: white;
}

.image-viewer-container .img-navigation-container {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
}

.image-viewer-container-transparent.img-navigation-container {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
}

.preview-dropdown-icon {
  font-size: 12px;
  margin: 3px 0 0 4px;
}

.preview-dropdown {
  display: flex;
  gap: 3px;
}

.sun-icon {
  margin-top: 1px;
  margin-right: 1px;
}
.sun-icon-toggle {
  margin-top: 2px;
  font-size: 17px;
}

.moon-icon {
  margin-right: 1px;
}
.moon-icon-toggle {
  margin-top: 1px;
  font-size: 17px;
}

.image-viewer-container .img-navigation-container .clear-sort-container {
  display: flex;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.image-viewer-container-transparent .img-navigation-container .clear-sort-container {
  display: flex;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.image-viewer-container-transparent .react-viewer-mask {
  background: repeating-conic-gradient(#ddd 0% 25%, transparent 0% 50%) 50%/1.5% 1.5%;
}

.actual-preview-container {
  position: absolute;
  display: flex;
  gap: 20px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.actualLinkContainer {
  display: flex;
  justify-content: right;
  cursor: pointer;
}

.previewContainer {
  display: flex;
  justify-content: right;
  cursor: pointer;
  /* top: 50%;
      transform: translateY(-50%); */
}

.page-container {
  background-color: rgb(241, 239, 239);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.previewLink-enabled,
.actualLink-enabled {
  color: teal;
}

.previewLink-disabled,
.actualLink-disabled {
  color: black;
}

.toggle-theme-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  position: absolute;
  top: 28px;
  right: 85px;
  width: 100px;
  height: 30px;
}

.canvas-container {
  /* margin-top: 14%; */
  background-color: white; /* Base color */
  background-image: linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc), linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc);
  background-size: 20px 20px; /* Size of each square */
  background-position: 0 0, 10px 10px; /* Positioning the layers */
  position: fixed;
  z-index: 1000;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.canvas-container .close-btn {
  position: absolute;
  top: 20px;
  right: 28px;
}
.canvas-container .close-btn .close-icon {
  font-size: 30px;
  color: black;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
.canvas-container .close-btn .close-icon-light {
  color: #828282;
}
.canvas-container .close-btn .close-icon:hover {
  transform: scale(1.2);
}

.total-text {
  position: absolute;
  bottom: 10px;
  right: 20px;
  border-radius: 5px;
  color: grey;
  z-index: 10000000;
}

.react-viewer-showTotal {
  display: none;
}

.react-viewer-attribute {
  color: black;
}

.imageToolTip-canvas {
  width: 100%;
  height: 100%;
}

.image-viewer-canvas {
  width: 100%;
  height: 100%;
}

.canvas-container {
  width: 100%;
  height: 100%;
}

.save-btn {
  position: absolute;
  bottom: 30px;
  left: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 25px;
  background-color: #ad9442;
  border: 0;
  max-width: -moz-fit-content;
  max-width: fit-content;
  justify-self: flex-end;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition-property: background-color;
  transition: 250ms linear;
}
.save-btn .save-icon {
  font-size: 30px;
  color: black;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
.save-btn .save-icon:hover {
  transform: scale(1.2);
}

@media only screen and (max-width: 768px) {
  .image-viewer-container .img-navigation-container .clear-sort-container {
    display: none;
    position: relative;
  }
} 
/* .imageToolTip{

    position: fixed;
    top: 50%;
    left: 65%;
    transform: translate(-50%, -50%);
    background-color: #333;
    color: #fff;
    padding: 10px;
    z-index: 999;


} *//*# sourceMappingURL=ImageViewer.css.map */