.custom-navbar {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  align-items: center;
}
.custom-navbar img {
  height: 25px;
  width: 25px;
  padding: 10px;
  background-color: rgb(241, 239, 239);
  -o-object-fit: contain;
     object-fit: contain;
  transition: transform 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  opacity: 0;
  cursor: pointer;
}
.custom-navbar .active-img {
  border: 4px solid #077c82;
  opacity: 1;
  height: 50px;
  width: 50px;
  transition: opacity 0.3s ease-in-out;
}
.custom-navbar .prev-img,
.custom-navbar .next-img {
  opacity: 0.5;
}
.custom-navbar button {
  background-color: transparent;
  border: none;
  font-size: 20px;
  color: #077c82;
}
.custom-navbar button:disabled {
  color: grey;
}/*# sourceMappingURL=CustomImageViewerNavBar.css.map */