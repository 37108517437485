* {
  /* height: 100vh; */
  /* width: 100vw; */
  /* overflow: hidden; */
}
.app ::-webkit-scrollbar {
  width: 20px;
}
.app ::-webkit-scrollbar-track {
  background-color: transparent;
}
.app ::-webkit-scrollbar-thumb {
  background-color: #bebebe;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
.app ::-webkit-scrollbar-thumb:hover {
  background-color: #bebebe;
}
.app {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  max-height: -webkit-fill-available;
  justify-content: space-between;
}

.body-container {
  flex: 1;
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}
.border-shadow-container {
  padding: 20px;
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.button {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 25px;
  background-color: #ad9442;
  border: 0;
  max-width: -moz-fit-content;
  max-width: fit-content;
  justify-self: flex-end;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition-property: background-color;
  transition: 250ms linear;
}

/* .button:hover {
  background-color: #077c82;
  
} */

.button.warning {
  color: black;
  background-color: rgba(53, 53, 53, 0.185);
}
/* .button.warning:hover {
  color: white;
  background-color: rgb(158, 158, 158);
} */

.button.circle {
  border-radius: 50%;
  padding: 10px;
}

.button.min-width {
  min-width: 300px;
}
.action-btn {
  background-color: #ad9442;
  color: white;
}

button:disabled {
  background-color: rgb(158, 158, 158);
  cursor: pointer;
  pointer-events: none;
}
.full-flex {
  flex: 1;
}
