.search-bar {
  max-width: 500px;
  width: 100%;
}

.auto-refresh-checkbox {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 10px 10px;
  gap: 15px;
  border-radius: 10px;
  white-space: nowrap;
}

.link {
  color: #077c82;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}/*# sourceMappingURL=LivePlatfromPage.css.map */