.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  padding: 0px 15px 15px 15px;
  z-index: 1050;
  background-color: rgb(223, 230, 230);
  height: 100%;
  &.active {
    display: flex;
    width: 300px;
    background-color: rgb(223, 230, 230);
    height: 100%;
  }
  .close-btn {
    display: none;
    color: black;
    fill: black;
  }
  .brand-logo-container {
    display: flex;
    flex-direction: row;
    gap: 2;
    // align-items: center;
    // justify-content: center;
    height: 71px;
    width: 100%;
    cursor: pointer;
  }
  .wl-logo {
    height: 30px;
  }
  .gpt-logo {
    // height: 40px;
    margin-top: 10px;
    height: 30px;
    object-fit: fill;
  }
  .grey-screen-sidebar {
    display: none;
  }
  .footer-sidebar {
    .wl-long-logo {
      height: 25px;
    }
  }
}

@media screen and (max-width: 768px) {
  .sidebar {
    display: none;
  }
  .sidebar.active {
    display: flex;
    width: 300px;
    left: 0;
    position: absolute;
    .grey-screen-sidebar {
      content: "";
      position: fixed;
      display: block;
      top: 0;
      left: 300px;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
    }
    .close-btn {
      position: absolute;
      display: flex;
      top: 10px;
      right: 10px;
      font-size: 30px;

      cursor: pointer;
    }
  }
}
