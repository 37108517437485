.chat-box-container {
  position: sticky;
  margin: 0 auto;
  background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 13.94%, #fff 50.73%);
  bottom: 0px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  //   background-color: transparent;
  align-items: center;
  //   margin: 0 auto;
  padding: 10px;
  width: 100%;
  z-index: 1;
  gap: 10px;

  .stop-btn-container {
    // width: fit-content;

    // align-self: flex-end;
    width: 100%;
    width: 800px;
    padding: 10px;
    button {
      padding: 10px;
      border-radius: 5px;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

      float: right;
      border: none;
    }
  }

  .under-text {
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    color: #303030;
    margin: 0;
    &.grey {
      font-weight: 600;
      color: rgb(156, 156, 156);
    }
  }
}

.chat-container {
  position: relative;
  flex: 1;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-margin-top: auto;

  .human-chat-container {
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    // font-weight: 500;
  }
  .bot-chat-container {
    width: 100%;
    background-color: rgba(223, 230, 230, 0.589);
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    // font-weight: 700;
    display: flex;
    white-space: pre-wrap;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .chat-content {
    display: flex;
    align-items: start;
    gap: 25px;
    padding: 25px 10px;
    width: 100%;
    max-width: 800px;
    .chat {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .time-text {
        font-style: italic;
        font-size: 10px;
        color: rgb(156, 156, 156);
        font-weight: 600;
      }
    }
  }
  //   border-bottom: 1px solid #d9d9e3;
  .recommend-container {
    max-width: 950px;
  }
  .recommend-question-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 15px;
    font-size: 14px;
    padding: 12px 16px;
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
    }
    p {
      margin: 0;
    }
  }
  &.initial {
    img {
      width: 72px;
    }
  }
}
.loading-container {
  flex: 1;
  // background-color: red;
  height: 100%;
  .ant-spin-container {
    // display: flex;
    // flex-direction: column;
    // background-color: green;
    height: 100%;
    flex: 1;
  }
}

.pdf-viewer {
  width: 100%;
  height: 100%;
  border: none;
}

.full-flex {
  flex: 1;
}

.file-select {
  color: black;
  width: 100%;
  font-weight: 700;
  &.error {
    color: red;
  }
}
.source-btn {
  // padding: 5px;
  border-radius: 2px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

  border: none;
}
.open-btn {
  position: absolute;
  z-index: 100;
  left: 20px;
  top: 55px;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

  border: none;
}
