.sidebar {
  .navbar-brand {
    img {
      height: 40px;
    }
  }
  .dropdown {
    .user-container {
      color: black;
    }
  }
  .offcanvas-body {
    padding: 0;
  }
  .sidebar-menu {
    padding: 10px;
    transition: background-color 200ms linear;
    cursor: pointer;
    &:hover {
      background-color: rgba(183, 183, 183, 0.403);
    }
  }
  .navbar-brand {
    margin: 0;
  }
}
