.table-stock .table-row-light {
  background-color: #ffffff;
}
.table-stock .table-row-dark {
  background-color: #fbfbfb;
}
.table-stock .ant-table-thead .ant-table-cell {
  background-color: #077c82;
  color: white;
}
.table-stock .ant-table-cell.ant-table-column-sort.ant-table-column-has-sorters {
  background-color: #077c82;
  color: white;
}
.table-stock .ant-table-cell.ant-table-column-sort.ant-table-column-has-sorters:hover {
  background-color: #077c82;
}
.table-stock .ant-table-cell.ant-table-column-has-sorters {
  background-color: #077c82;
  color: white;
}
.table-stock .ant-table-cell.ant-table-column-has-sorters:hover {
  background-color: #077c82 !important;
}
.table-stock .ant-table-wrapper {
  margin-bottom: 0;
}
.table-stock .ant-table-pagination.ant-pagination {
  margin-top: 15px;
  padding-bottom: 0;
}
.table-stock .ant-select-selection-item {
  font-size: 16px;
}
.table-stock .anticon.active {
  color: white;
}
.table-stock .anticon {
  color: transparent;
}/*# sourceMappingURL=StockSymbolTable.css.map */