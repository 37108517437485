.form-container {
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100%;
  overflow: hidden;
}

.responsive-border {
  border-left: 1px solid #e8e8e8;
}

@media only screen and (max-width: 768px) {
  .form-container {
    width: 100%;
    height: 40%;
    overflow: scroll;
  }
  .responsive-border {
    border-left: none;
    border-top: 1px solid #e8e8e8;
  }
} /*# sourceMappingURL=BrandQualityCheck.css.map */
.nonMandatoryInputFields {
  margin-left: 10px;
}
.dimensionsType {
  font-size: 12px;
}
.descriptionField {
  margin: 0;
}
.commentsField {
  margin: 0;
}
.brandPageCharCount {
  margin: 0px;
  /* margin-left:370px; */
  margin-left: 70%;
  font-size: 13px;
  font-style: italic;
}
.imagePageCharCount {
  margin: 0px;
  /* margin-left:370px; */
  margin-left: 71%;
  font-size: 13px;
  font-style: italic;
}
.sizeLabel {
  height: 65px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  line-height: 17px;
}
.imageNotFoundMsg {
  font-size: 15px;
  padding-bottom: 50px;
}
